import React from 'react'
import MainTemplate from '../../components/Template/MainTemplate'
import SocialMediaCard from '../../components/Cards/SocialMediaCard'
import { Icon } from '@iconify/react'
import securityScanFilled from '@iconify/icons-ant-design/security-scan-filled'
import Seo from '../../components/Partials/Seo'

export default function Contact() {
  return (
    <MainTemplate>
      <Seo
        title="Contact - Dicka Ismaji"
      />
      <div className="mx-auto max-w-xl p-4 pb-12">
        <div>
          <p>Here is my contact, you can DM me, but i not promise to answer soonly, but i will try to answer as soon as possible :D </p>
        </div>
        <div className="sidebar sticky top-0" style={{
          minWidth: '200px',
          maxWidth: '320px'
        }}>
          <SocialMediaCard />
          <a className="text-red-400" href="https://secreto.site/a95rx" target="_blank" rel="noreferrer">
            <Icon icon={securityScanFilled} className='inline mr-4' />
            Ask me secretly with Secreto
          </a>
        </div>
      </div>
    </MainTemplate>
  )
}